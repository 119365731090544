import React from "react";
import loadable from "@loadable/component";

const SimpleContentWithAuth = loadable(() =>
  import("../../backend/varieties/SimpleContent")
);
const SimpleContent = loadable(() => import("../varieties/SimpleContent"));

const SimpleContentWithMoreWithAuth = loadable(() =>
  import("../../backend/varieties/SimpleContentWithMore")
);
const SimpleContentWithMore = loadable(() =>
  import("../varieties/SimpleContentWithMore")
);

const SimpleSliderWithAuth = loadable(() =>
  import("../../backend/varieties/SimpleSlider")
);
const SimpleSlider = loadable(() => import("../varieties/SimpleSlider"));

const SimpleContactWithAuth = loadable(() =>
  import("../../backend/varieties/SimpleContact")
);

const SimpleContact = loadable(() => import("../varieties/SimpleContact"));

const SimpleEmployeeSliderWithAuth = loadable(() =>
  import("../../backend/varieties/SimpleEmployeeSlider")
);

const SimpleEmployeeSlider = loadable(() =>
  import("../varieties/SimpleEmployeeSlider")
);

const SimpleHeaderWithAuth = loadable(() =>
  import("../../backend/varieties/SimpleHeader")
);

const SimpleHeader = loadable(() => import("../varieties/SimpleHeader"));

const AccordionWithImageWithAuth = loadable(() =>
  import("../../backend/varieties/AccordionWithImage")
);

const AccordionWithImage = loadable(() =>
  import("../varieties/AccordionWithImage")
);

const ServiceOverviewWithAuth = loadable(() =>
  import("../../backend/varieties/ServiceOverview")
);

const ServiceOverview = loadable(() => import("../varieties/ServiceOverview"));

const NewsTeaserWithAuth = loadable(() =>
  import("../../backend/varieties/NewsTeaser")
);

const NewsTeaser = loadable(() => import("../varieties/NewsTeaser"));

const NewsOverviewWithAuth = loadable(() =>
  import("../../backend/varieties/NewsOverview")
);

const NewsOverview = loadable(() => import("../varieties/NewsOverview"));

const PressNoteOverviewWithAuth = loadable(() =>
  import("../../backend/varieties/PressNoteOverview")
);

const PressNoteOverview = loadable(() =>
  import("../varieties/PressNoteOverview")
);

const JobsOverviewWithAuth = loadable(() =>
  import("../../backend/varieties/JobsOverview")
);

const JobsOverview = loadable(() => import("../varieties/JobsOverview"));

const ApprenticeshipOverviewWithAuth = loadable(() =>
  import("../../backend/varieties/ApprenticeshipOverview")
);

const ApprenticeshipOverview = loadable(() =>
  import("../varieties/ApprenticeshipOverview")
);

const SearchWithAuth = loadable(() => import("../../backend/varieties/Search"));

const Search = loadable(() => import("../varieties/Search"));

function VarietySelector({ auth, components = [] }) {
  function renderVarietiesWithAuth() {
    return components.map((component, index) => {
      switch (component.shortcut) {
        case "C01":
          return (
            <SimpleContentWithAuth
              key={component._id + index}
              index={index}
              context={component.content}
            />
          );
        case "C02":
          return (
            <SimpleContentWithMoreWithAuth
              key={component._id + index}
              index={index}
              context={component.content}
            />
          );
        case "C03":
          return (
            <SimpleSliderWithAuth
              key={component._id + index}
              index={index}
              context={component.content}
            />
          );
        case "C04":
          return (
            <SimpleContactWithAuth
              key={component._id + index}
              index={index}
              context={component.content}
            />
          );
        case "C05":
          return (
            <NewsTeaserWithAuth
              key={component._id + index}
              index={index}
              context={component.content}
            />
          );
        case "C06":
          return (
            <SimpleEmployeeSliderWithAuth
              key={component._id + index}
              index={index}
              context={component.content}
            />
          );
        case "C07":
          return (
            <SimpleHeaderWithAuth
              key={component._id + index}
              index={index}
              context={component.content}
            />
          );
        case "C08":
          return (
            <AccordionWithImageWithAuth
              key={component._id + index}
              index={index}
              context={component.content}
            />
          );
        case "C09":
          return (
            <ServiceOverviewWithAuth
              key={component._id + index}
              index={index}
              context={component.content}
            />
          );
        case "C10":
          return (
            <NewsOverviewWithAuth
              key={component._id + index}
              index={index}
              context={component.content}
              settings={component.settings}
            />
          );
        case "C11":
          return (
            <PressNoteOverviewWithAuth
              key={component._id + index}
              index={index}
              context={{ ...component.content, category: "Pressemitteilung" }}
              settings={component.settings}
            />
          );
        case "C12":
          return (
            <PressNoteOverviewWithAuth
              key={component._id + index}
              index={index}
              context={{ ...component.content, category: "Wir in der Presse" }}
              settings={component.settings}
            />
          );
        case "C13":
          return (
            <JobsOverviewWithAuth
              key={component._id + index}
              index={index}
              context={component.content}
              settings={component.settings}
            />
          );
        case "C14":
          return (
            <ApprenticeshipOverviewWithAuth
              key={component._id + index}
              index={index}
              context={component.content}
              settings={component.settings}
            />
          );
        case "C15":
          return (
            <SearchWithAuth
              key={component._id + index}
              index={index}
              context={component.content}
              settings={component.settings}
            />
          );
        default:
          return <div key={component._id + index} />;
      }
    });
  }

  function renderVarieties() {
    return components.map((component, index) => {
      switch (component.shortcut) {
        case "C01":
          return (
            <SimpleContent
              key={component._id + index}
              index={index}
              context={component.content}
            />
          );
        case "C02":
          return (
            <SimpleContentWithMore
              key={component._id + index}
              index={index}
              context={component.content}
            />
          );
        case "C03":
          return (
            <SimpleSlider
              key={component._id + index}
              index={index}
              context={component.content}
            />
          );
        case "C04":
          return (
            <SimpleContact
              key={component._id + index}
              index={index}
              context={component.content}
            />
          );
        case "C05":
          return (
            <NewsTeaser
              key={component._id + index}
              index={index}
              context={component.content}
            />
          );
        case "C06":
          return (
            <SimpleEmployeeSlider
              key={component._id + index}
              index={index}
              context={component.content}
            />
          );
        case "C07":
          return (
            <SimpleHeader
              key={component._id + index}
              index={index}
              context={component.content}
            />
          );
        case "C08":
          return (
            <AccordionWithImage
              key={component._id + index}
              index={index}
              context={component.content}
            />
          );
        case "C09":
          return (
            <ServiceOverview
              key={component._id + index}
              index={index}
              context={component.content}
            />
          );
        case "C10":
          return (
            <NewsOverview
              key={component._id + index}
              index={index}
              context={component.content}
              settings={component.settings}
            />
          );
        case "C11":
          // Pressemitteilung
          return (
            <PressNoteOverview
              key={component._id + index}
              index={index}
              context={{ ...component.content, category: "Pressemitteilung" }}
              settings={component.settings}
            />
          );
        case "C12":
          // Wir in der Presse
          return (
            <PressNoteOverview
              key={component._id + index}
              index={index}
              context={{ ...component.content, category: "Wir in der Presse" }}
              settings={component.settings}
            />
          );
        case "C13":
          return (
            <JobsOverview
              key={component._id + index}
              index={index}
              context={component.content}
              settings={component.settings}
            />
          );
        case "C14":
          return (
            <ApprenticeshipOverview
              key={component._id + index}
              index={index}
              context={component.content}
              settings={component.settings}
            />
          );
        case "C15":
          return (
            <Search
              key={component._id + index}
              index={index}
              context={component.content}
              settings={component.settings}
            />
          );
        default:
          return <div key={component._id + index} />;
      }
    });
  }

  if (
    auth &&
    Array.isArray(auth.role) &&
    !!["support", "publisher", "user"].find((role) => auth.role.includes(role))
  )
    return renderVarietiesWithAuth();
  return renderVarieties();
}

export default VarietySelector;
